import React, { useCallback, useState } from "react";
import firebase from "firebase/app";

export const Login: React.FC = () => {
    const [email, setEmail] = useState("");

    const sendMail = useCallback((e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const func = async () => {
            var actionCodeSettings = {
                // URL you want to redirect back to. The domain (www.example.com) for this
                // URL must be in the authorized domains list in the Firebase Console.
                url: 'http://localhost:3000',
                // This must be true.
                handleCodeInApp: true,
            };

            try {
                await firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings);
            } catch (error) {
                console.error("ログインエラー:", error);
                return;
            }
            window.localStorage.setItem('emailForSignIn', email);
        };

        func();
    }, [email]);

    return <div>
        <form onSubmit={sendMail}>
            <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="メールアドレス" />
            <button type="submit">送信</button>
        </form>
    </div>
};