import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAW9gJN6frX2BLfD8P9Ve2Cy_POvgltWRw",
  authDomain: "wagaya-no-dashboard.firebaseapp.com",
  projectId: "wagaya-no-dashboard",
  storageBucket: "wagaya-no-dashboard.appspot.com",
  messagingSenderId: "30219414008",
  appId: "1:30219414008:web:14a29239237f1dfd830e0d",
  measurementId: "G-W58CFBNKQL"
};

firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
