import React, { createContext, useEffect } from 'react';
import firebase from "firebase/app";
import './App.css';
import { Login } from "./Login";

type AuthState = "None" | "Login" | "Logout";

type UserContextValue = {
  authState: AuthState;
}

function App() {
  const initialValue: UserContextValue = { authState: "None" };
  const userContext = createContext<UserContextValue>(initialValue);

  useEffect(() => {
    (async () => {
      if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
        let email = window.localStorage.getItem('emailForSignIn');
        if (!email) {
          email = window.prompt('Please provide your email for confirmation');
        }
        if (!email) {
          return;
        }

        try {
          const result = await firebase.auth().signInWithEmailLink(email, window.location.href);
          console.log(result);
        } catch (error) {
          console.error("ログインエラー:", error);
          return;
        }
        window.localStorage.removeItem('emailForSignIn');
        window.history.replaceState(null, "", "/");
      }
    })();
  }, []);

  useEffect(() => {
    return firebase.auth().onAuthStateChanged(
      (user) => {
        console.log(user?.toJSON());
      },
      (error) => {
        console.error("認証エラー:", error)
      });
  }, []);

  if (true) {
    return <Login />;
  }


  return (
    <userContext.Provider value={initialValue}>
      <div className="App">
      </div>

    </userContext.Provider>
  );
}

export default App;
